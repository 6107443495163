// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div } from '@tackl';
import Topo from '@images/ps-topo-bg.png';

// Exports
// ------------
export const Jacket = styled(Section)(p => css`
    position: relative;
    ${p.theme.vh};
    overflow: hidden;
`);

export const Headline = styled.div(props => css`
    position: absolute;
    bottom: -1.5rem;
    z-index: 6;

    width: 100%;
    text-align: right;

    font-family: ${props.theme.fancy};
    font-size: 18.2vw;
    line-height: 100%;
    text-indent: -1vw;
    color: ${props.theme.black};

    transition: all 1.4s ${props.theme.bezzy};
    transform: translateX(${props.isVisible ? `0` : `50vw`});

    ${breakup.medium` bottom: -3rem; `}
    ${breakup.xlarge` bottom: -4vw; `}
`);

export const Categories = styled.div(props => css`
    position: absolute;
    z-index: 12;
    
    width: 100vw;
    ${props.theme.vh};

    display: flex;
    align-items: center;

    transition: all 1.4s ${props.theme.bezzy};
    transition-delay: .3s;
    transform: translateX(${props.isVisible ? `0` : `50vw`});

    .swiper {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;

        &-wrapper {
            display: flex;
            align-items: center;
        }

        &-slide {
            display: flex;
            align-items: center;
        }
    }
`);



export const Topographic = styled(Div)((p) => css`
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url(${Topo}) repeat;

    transition: all 1.2s ${p.theme.bezzy};
    transition-delay: .4s;
    transform-origin: center center;
    
    max-height: 0;

    ${p.isVisible && css`
        width: 60vw;
        max-height: 50vh;
        ${breakup.medium` max-height: 75vh; `}
    `}
    
    ${p.notVisible && `max-height: 0;`}
`);