// Imports
// ------
import React from 'react';
import SmoothScroll from '@parts/SmoothScroll';
import List from '@parts/Gallery/List';
import { graphql } from 'gatsby';

// Page
// ------
const GalleryPage = ({ data }) => (
	<SmoothScroll>
		<List data={data.galleries.nodes} />
	</SmoothScroll>
);

export default GalleryPage;

// GraphQL
// ------
export const query = graphql`
    query GalleriesQuery {
		galleries: allDatoCmsGallery {
			nodes {
                slug
                title

				imageList {
					alt
					data: gatsbyImageData(
						width: 600
						placeholder: BLURRED
						layout: FULL_WIDTH
					)
				}
            }
		}
	}
`;