// Imports
// ------------
import React, { useEffect, useState } from 'react';
import Card from './Card';
import useSound from 'use-sound';
import Click from '@sounds/click.mp3';
import SwiperCore, { Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { loader, pagetrans } from '@states';
import { observer } from 'mobx-react-lite';

// Styles
// ------------
import {
    Jacket,
    Headline,
    Categories,
    Topographic
} from './styles';
import 'swiper/css';

// Swiper
// ------
SwiperCore.use([Mousewheel, FreeMode]);

// Component
// ------------
const GalleryList = ({ data }) => {
    // NOTE • States
    const [topoVisible, setTopoVisible] = useState(false);

    // NOTE • Functions
    const [play] = useSound(Click, { volume: 1 });

    const handleCategory = () => {
        play();
    }

    useEffect(() => {
        let TopoAnim;

        if (loader.isComplete) {
            // NOTE • Show the topo
            TopoAnim = setTimeout(() => {
                setTopoVisible(true);
            }, 1000);
        }

        return () => {
			clearTimeout(TopoAnim);
		};
    });

    return (
        <Jacket>
            <Headline isVisible={loader.isComplete && pagetrans.isDefault}>The Galleries</Headline>

            <Topographic isVisible={topoVisible} />

            <Categories isVisible={loader.isComplete && pagetrans.isDefault}>
                <Swiper
                    spaceBetween={36}
                    loop={true}
                    mousewheel={true}
                    freeMode={true}
                    draggable={true}
                    allowTouchMove={true}
                    // centerSlides={true}
                    slidesPerView={1.2}
                    breakpoints={{
						640: {
							slidesPerView: 2,
						},
						900: {
							slidesPerView: 2.9,
                            draggable: false,
                            allowTouchMove: false,
						},
						1600: {
							slidesPerView: 3.4,
						},
						1900: {
							slidesPerView: 4.2,
						},
					}}
                >
                    {data.map((cat, i) => (
                        <SwiperSlide key={i} onClick={handleCategory}>
                            <Card
                                slug={`/gallery/` + cat.slug}
                                title={cat.title}
                                image={cat.imageList}
                                count={cat.imageList}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Categories>
        </Jacket>
    );
}

export default observer(GalleryList);