// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, H3 } from '@tackl';
import { TransitionLink } from 'gatsby-plugin-transitions';

// Exports
// ------------
export const Image = styled.div(props => css`
    position: relative;
    width: 100%;
    height: 50vh;

    ${breakup.xlarge` height: 64vh; `}

    .gatsby-image-wrapper, img {
        transition: all 1s ${props.theme.bezzy} !important;
        height: 100%;
    }
`);

export const Title = styled(H3)(props => css`
    transition: clip-path 1s ${props.theme.bezzy};
    ${breakup.large` clip-path: inset(50% 0% 50% 0%); `}

    display: block;
    position: absolute;

    padding: .6rem 2.4rem;
    bottom: -3.2rem;

    color: ${props.theme.bc1};

    ${breakup.medium`
        width: 90%;
        bottom: -3.6rem;
    `}
    ${breakup.xlarge`
        width: 100%;
        bottom: -5rem;
    `}

    span {
        font-size: 1.2rem;
        font-weight: bold;
        color: ${props.theme.white};
    }
`);

export const Jacket = styled(TransitionLink)`
    position: relative;
    display: block;
    width: 100%;

    ${breakup.xlarge`
        &:hover {
            ${Title} {
                clip-path: inset(0% 0% 0% 0%);
            }

            ${Image} {
                img {
                    transform: scale(1.1) rotate(-2deg);
                }
            }
        }
    `}
`;