// Imports
// ------------
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------------
import { Jacket, Image, Title } from './styles';

// Component
// ------------
const GalleryCard = ({ slug, title, image, count }) => {
    return (
        <Jacket to={slug} className="ch">
            <Image>
                <GatsbyImage image={image[0].data} alt={image[0].alt} />
            </Image>

            <Title>{title} <span>{count.length}</span></Title>
        </Jacket>
    );
}

export default GalleryCard;